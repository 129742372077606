import React, { useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { AppContext } from '../../contexts/AppContext';
import { LOGOUT } from '../../reducers/AuthReducer';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import gerFlag from '../../brand/ger-flag.png';
import engFlag from '../../brand/eng-flag.png';
import Icon from './Icon';
import logoImg from './../../brand/bankingtool_logo.png';
import DemoApp from './DemoApp';

const MainNavbar = withRouter(
  ({
    history,
    toggleSideNav,
    sideNavIsOpen,
    toggleshowLinkTitle,
    showLinkTitle,
  }) => {
    const { dispatch, navStat, translation, locale } = useContext(AppContext);
    const [isOpen, toggleIsOpen] = useState(false);
    const Auth = useContext(AuthContext);
    const toggle = () => toggleIsOpen(!isOpen);
    const props = useSpring({
      to: {
        opacity: 1,
        marginTop: 0,
      },

      from: {
        opacity: 0,
        marginTop: -500,
      },
    });

    const logOut = () => {
      localStorage.removeItem('techVoiceToken');

      Auth.dispatch({ type: LOGOUT });
      history.push('/login');
    };

    const animateSideMenu = () => {
      toggleSideNav(!sideNavIsOpen);

      if (!showLinkTitle) {
        setTimeout(() => {
          toggleshowLinkTitle(!showLinkTitle);
        }, 200);
      } else {
        toggleshowLinkTitle(!showLinkTitle);
      }
    };
    var lang = localStorage.getItem('locale');
    return (
      <animated.div className="main-nav sticky-top" style={props}>
        <DemoApp />
        <header className="main-nav">
          <Navbar className="main-navbar flex-md-nowrap p-0" light expand="sm">
            <div
              className={
                navStat
                  ? 'logo-box d-flex align-items-center'
                  : 'logo-box logo-text-hide d-flex align-items-center'
              }
            >
              <NavbarBrand href="/" className="py-1 mr-0">
                {' '}
                <img
                  src={logoImg}
                  className={!navStat ? 'hlLogo' : 'unhlLogo'}
                  style={{
                    width: '35px',
                    marginRight: '17px',
                    marginLeft: '7px',
                  }}
                  alt="Finanzierung"
                />
                <span
                  className={navStat ? 'MnavName' : 'MnavName noMNavName'}
                  style={{
                    position: 'unset !important',
                    marginLeft: 'unset !important',
                  }}
                >
                  Finanzierung
                </span>{' '}
              </NavbarBrand>
              {/* <span onClick={animateSideMenu} className="mx-2">
                {" "}
              </span> */}
            </div>
            <div className="text-left">
              <span
                onClick={() => {
                  dispatch({ navStat: !navStat });
                }}
              >
                {' '}
                {navStat ? (
                  <i className="fas fa-times nav-times"></i>
                ) : (
                  <i className="fas fa-bars nav-bars"></i>
                )}
              </span>
            </div>

            <button
              onClick={() => toggleIsOpen(!isOpen)}
              type="button"
              className="navbar-toggler"
            >
              <span>
                <i className="fas fa-ellipsis-v"></i>
              </span>
            </button>

            <Collapse className="" isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav inNavbar className="ml-2">
                  <DropdownToggle nav caret>
                    {`${Auth.user.firstName} ${Auth.user.lastName}`}
                  </DropdownToggle>
                  <DropdownMenu right className="mb-2">
                    <DropdownItem onClick={logOut}>
                      <i className="fas fa-sign-out-alt"></i>{' '}
                      {translation.logout[locale]}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown className="ml-2" nav inNavbar>
                  <DropdownToggle nav>
                    <Icon
                      height="13"
                      width="15"
                      src={lang === 'en' ? engFlag : gerFlag}
                    />
                  </DropdownToggle>

                  <DropdownMenu right className="mb-1">
                    <DropdownItem
                      onClick={() => {
                        localStorage.setItem(
                          'locale',
                          lang === 'en' ? 'de' : 'en'
                        );
                        dispatch({ locale: lang === 'en' ? 'de' : 'en' });
                      }}
                    >
                      <Icon
                        height="13"
                        width="15"
                        src={lang !== 'en' ? engFlag : gerFlag}
                      />
                      {lang !== 'en' ? ' English' : ' Deutsch'}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
        </header>
      </animated.div>
    );
  }
);

export default MainNavbar;
