import React, { useContext } from 'react';
import logo from '../../brand/bankingtool_logo.png';
import Logo from '../common/Logo';
import {
  Navbar,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';

import Button from '../common/Button';
import gerFlag from '../../brand/ger-flag.png';
import engFlag from '../../brand/eng-flag.png';
import Icon from '../common/Icon';

import { AppContext } from './../../contexts/AppContext';

const LoginForm = ({ state, dispatch, handleSubmit }) => {
  const {
    userName,
    password,
    formMessage,
    formPassMessage,
    inValidForm,
  } = state;

  const { dispatches, translation, locale } = useContext(AppContext);
  var lang = localStorage.getItem('locale');
  return (
    <Card
      style={{
        margin: '20% auto 0 auto',
        boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0.5rem 1rem',
      }}
    >
      <Navbar
        className="main-navbar flex-md-nowrap p-0"
        style={{
          backgroundColor: 'transparent',
          height: '49px',
          position: 'absolute',
          right: 0,
        }}
        light
        expand="md"
      >
        <Collapse className="mr-2" navbar>
          <Nav
            className="ml-auto"
            style={{ height: '50px', paddingTop: '7px' }}
            navbar
          >
            <UncontrolledDropdown
              id="lang"
              className="ml-2"
              style={{ marginRight: '7px', backgroundColor: 'transparent' }}
              nav
              inNavbar
            >
              <DropdownToggle nav>
                <Icon
                  height="13"
                  width="15"
                  src={lang === 'en' ? engFlag : gerFlag}
                />
              </DropdownToggle>
              <DropdownMenu
                right
                className="mb-2"
                style={{ fontSize: '.795rem' }}
              >
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem('locale', lang === 'en' ? 'de' : 'en');

                    dispatches({ locale: lang === 'en' ? 'de' : 'en' });
                  }}
                >
                  <small style={{ marginRight: '10px' }}>
                    <Icon
                      height="13"
                      width="15"
                      src={lang === 'en' ? gerFlag : engFlag}
                    />
                  </small>
                  {lang === 'en' ? 'German' : 'English'}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
      <CardBody>
        <div>
          <Logo 
            src={logo} 
            style={{ width: '60px' }} 
            text="Finanzierung" 
            textStyle={{ 
              margin:'auto 5px',
              fontSize:'30px',
              fontWeight:'bold'
            }}/>
        </div>

        <Form onSubmit={handleSubmit} style={{ margin: '0 auto' }}>
          <FormGroup>
            <Label for="exampleEmail">{translation.userName[locale]}</Label>
            <Input
              className="login-input"
              invalid={inValidForm}
              onChange={(e) =>
                dispatch({
                  type: 'INPUT',
                  field: 'userName',
                  value: e.currentTarget.value,
                })
              }
              type="text"
              name="username"
              id="username"
              autoComplete="off"
              value={userName}
            />
            {inValidForm ? <FormFeedback>{formMessage}</FormFeedback> : ''}
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">{translation.Password[locale]}</Label>
            <Input
              className="login-input"
              invalid={inValidForm}
              onChange={(e) =>
                dispatch({
                  type: 'INPUT',
                  field: 'password',
                  value: e.currentTarget.value,
                })
              }
              type="password"
              name="password"
              id="password"
              value={password}
            />
            {inValidForm ? <FormFeedback>{formPassMessage}</FormFeedback> : ''}
          </FormGroup>

          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginTop: '20px' }}
          >
            <Button className="btn-exact" style={{ width: '100%' }}>
              {translation.login[locale]}
            </Button>
          </div>
          <div
            className="d-flex justify-content-between align-items-right"
            style={{ marginTop: '20px' }}
          >
            <small className="small-link mb-1">
              {translation.createAnAcc[locale]}
            </small>
            <small className="small-link">{translation.forPas[locale]}</small>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default LoginForm;
