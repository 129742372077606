import React from "react";

const Logo = props => {
  return (
    <div className="d-flex justify-content-center my-3">
      <img src={props.src} style={props.style} alt="logo"/>
      {
        props.text?
          <span className="logo__text" style={props.textStyle}>{props.text}</span>
        :''
      }
    </div>
  );
};

export default Logo;
